.studyReview {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fafcff;
  padding: 24px 20px;
  padding-bottom: 60px;
  min-height: calc(100vh - 280px);
  position: relative;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    width: 124px;
    height: 20px;
    background: url(../../images/logo.png) no-repeat center;
    background-size: 100%;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .message {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 14px;
  }

  .questions {
    display: flex;
    flex-direction: column;

    .question {
      padding: 12px 16px;
      background: #ffffff;
      border: solid #e9edf4 1px;
      margin-bottom: 8px;
      line-height: 28px;
      border-radius: 4px;
      font-size: 14px;

      .word {
        border: solid #e9edf4 1px;
        padding: 0 8px;
        text-align: center;
        height: 28px;
        box-sizing: border-box;
        font-size: 14px;
        margin-right: 2px;
        color: #396eff;
        transition: border 0.3s;
        appearance: none;
        border-radius: 2px;
        -webkit-appearance: none;

        &.isError {
          border-color: #ed7583;
        }

        &:focus {
          border-color: #396eff;
        }

        &::placeholder {
          color: #ccd8e8;
        }

        &.isDisabled {
          color: #414d6b;
          border-color: #e9edf4;
          background: #f7f8fb;
        }
      }
    }
  }
}
