.profile {
  background: #f7f8fb;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  .header {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 20px 16px 20px;

    .text {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .grade {
        font-size: 12px;
        color: #869ab7;
      }
    }

    .thumbnail {
      width: 60px;
      height: 60px;
      border: solid #fff 4px;
      border-radius: 50%;
      background: #efefef;
      box-shadow: 0 3px 2px 0 rgba(65, 77, 107, 0.3);
      position: relative;
      background-size: 60px !important;

      .icon {
        position: absolute;
        width: 24px;
        height: 24px;
        background: url(../../images/pic_standard_large.png) no-repeat center;
        background-size: 100%;
        right: -8px;
        bottom: -8px;
      }
    }
  }

  &.heavyDealer {
    .header {
      .thumbnail {
        .icon {
          background: url(../../images/pic_heavy_large.png) no-repeat center;
          background-size: 100%;
        }
      }

      .grade {
        color: #396eff;
      }
    }
  }

  .panel {
    padding: 20px;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;

      &:first-child {
        margin-bottom: 16px;
      }

      .column1 {
        width: 100%;
      }

      .column2 {
        width: 50%;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  .list {
    padding: 8px 20px;
    display: flex;
    flex-direction: column;

    .item {
      height: 56px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-decoration: none;
      color: #414d6b;
      font-size: 14px;
      line-height: 1.14;
      align-items: center;
      border-bottom: solid #e9edf4 1px;

      span {
        word-break: keep-all;
        white-space: nowrap;
      }

      .right {
        text-align: right;
        padding-right: 24px;
        background: url(../../images/arrow-gray.png) no-repeat right center;
        background-size: 16px !important;
      }

      &:last-child {
        border-bottom: none;
      }

      &.highlight {
        .right {
          color: #396eff;
        }
      }
    }
  }
}
