.reviewPanel {
  box-shadow: 0 2px 0 0 #527dcc;
  background-color: #396eff;
  height: 120px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .row {
    display: flex;

    .column {
      display: flex;
      flex-direction: column;
      color: #fff;
      min-width: 64px;
      margin-left: 20px;

      .value {
        font-size: 18px;
        line-height: 1.11;
        margin-bottom: 8px;
      }

      .label {
        font-size: 12px;
        line-height: 1;
      }
    }
  }

  .canvas {
    width: 84px;
    height: 84px;
    position: absolute;
    top: 20px;
    right: 24px;
  }
}
