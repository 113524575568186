.recentReviewTradeResult {
  background: #f3f4f6;
  padding: 16px;
  border-radius: 4px;
  border: solid #e9edf4 1px;
  position: relative;

  .title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;

    .inspectionImages {
      display: flex;
      flex-wrap: wrap;

      .inspectionImage {
        width: 44px;
        height: 44px;
        margin-bottom: 7px;
        margin-right: 7px;
        border-radius: 2px;
        background-size: 100% !important;
      }
    }

    .reductions {
      display: flex;
      flex-direction: column;
      padding-top: 8px;

      .reduction {
        font-size: 12px;
        line-height: 20px;
        padding-left: 20px;
        background: url(../../../images/rectangle.png) no-repeat left top;
        background-size: 16px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        display: flex;
        justify-content: space-between;

        .text {
          padding-right: 10px;
        }

        .price {
          color: #98a3ad;
          white-space: nowrap;
        }
      }
    }

    .hr {
      height: 1px;
      width: 100%;
      background: #dadfe1;
    }

    .screening {
      display: flex;
      padding-top: 16px;
      justify-content: space-between;

      .name {
        font-size: 12px;
        line-height: 16px;
        color: #98a3ad;
      }
    }
  }

  .normalText {
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 20px;

    &.old {
      display: flex;
      justify-content: space-between;

      .normalReductions {
        display: flex;
        flex-direction: column;
        max-width: 200px;

        .normalReduction {
          span {
            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .normalReductionPrice {
    color: #98a3ad;
    position: absolute;
    top: 20px;
    right: 16px;
    font-size: 12px;
    line-height: 1;
  }
}
