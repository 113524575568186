.input {
  color: #414d6b;
  width: 100%;
  padding: 16px 20px;
  border-radius: 4px;
  line-height: 1.5;
  box-sizing: border-box;
  transition: box-shadow 0.15s ease-in-out;
  border: 1px solid #e9edf4;
  font-size: 14px;

  &::placeholder {
    color: #e9edf4;
  }

  &:hover,
  &:focus {
    border: 1px solid #396eff;
  }
}
