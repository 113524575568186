.studyTest {
  padding: 20px;
  min-height: calc(100vh - 341px);
  box-sizing: border-box;
  background: #fafcff;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 124px;
    height: 20px;
    background: url(../../images/logo.png) no-repeat center;
    background-size: 100%;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
  }

  & > .title {
    font-size: 12px;
    color: #869ab7;
    line-height: 12px;
    padding-bottom: 19.5px;
    border-bottom: solid #e9edf4 1px;
    box-sizing: border-box;
    font-weight: normal;
    margin-bottom: 19.5px;
  }

  .questions {
    display: flex;
    flex-direction: column;

    .question {
      margin-bottom: 40px;

      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        display: flex;

        .no {
          margin-right: 4px;
        }
      }

      .choices {
        display: flex;
        flex-direction: column;

        .choice {
          display: flex;
          border: solid #e9edf4 1px;
          border-radius: 4px;
          margin-bottom: 8px;
          padding: 12px;
          background: #ffffff;
          align-items: center;

          &:last-child {
            margin-bottom: 0;
          }

          .shape {
            min-width: 24px;
            width: 24px;
            height: 24px;
            margin-right: 12px;
            background: url(../../images/checkbox_normal.svg) no-repeat center;
          }

          .content {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.2px;
          }

          &.isActivated {
            border-color: #396eff;
            background: #f4f8ff;

            .shape {
              background: url(../../images/success_checkbox_check.svg);
            }
          }

          &.isActivated.isDisabled {
            background: #ffffff;
            border-color: #e9edf4;

            .shape {
              background: url(../../images/checkbox_check_disabled.svg);
            }
          }
        }
      }
    }
  }
}
