@import '~@PRNDcompany/colors/colors';

.warning_page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.section_area {
  padding: 1.25rem;
}

.content_area {
  padding-bottom: 2.5rem;

  display: grid;
  gap: 2.5rem;

  .warning_count_area {
    display: flex;
    justify-content: space-between;

    .number_area {
      display: grid;
      grid-auto-flow: column;
      gap: 1.25rem;

      justify-content: start;
      justify-items: center;
      text-align: center;
    }

    .check_area {
      display: grid;
      gap: 0.5rem;
      grid-auto-flow: column;
      justify-content: end;
      align-items: center;

      .checked {
        width: 1.5rem;
        height: 1.5rem;
        background: url('./assets/checkbox_checked.svg');
      }

      .unchecked {
        width: 1.5rem;
        height: 1.5rem;
        background: url('./assets/checkbox_unchecked.svg');
      }
    }
  }

  .text_with_button_area {
    display: grid;
    gap: 1.25rem;

    .button_area {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      gap: 0.75rem;
    }
  }
}

.history_area {
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  background: $base_gray2;

  display: grid;
  gap: 0.5rem;
  align-content: flex-start;

  min-height: calc(100vh - 440px);

  .history_item {
    display: flex;
    justify-content: space-between;
  }
}

.objection_popup_content {
  display: grid;
  gap: 1.25rem;
}
