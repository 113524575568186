.reschedule {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 640px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    .title {
      font-weight: bold;
      font-size: 24px;
      color: #414d6b;
      line-height: 36px;
    }
    .carNumberInput {
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 600;
      border: 1px solid #e9edf4;
      width: 200px;
      padding: 20px;
      box-sizing: border-box;
      margin-top: 40px;
      &::placeholder {
        color: #ecf0f6;
      }
    }
    .description {
      margin-top: 20px;
      .subTitle {
        margin: 16px 0;
        font-weight: bold;
        color: #485877;
        font-size: 14px;
        line-height: 16px;
      }
      .content {
        font-size: 14px;
        line-height: 24px;
        color: #485877;
      }
    }
  }
  .button {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 20px;
    bottom: calc(20px + env(safe-area-inset-bottom));
    border-radius: 3px;
    padding: 20px;
    color: white;
    font-weight: bold;
    line-height: 16px;
    border: 1px solid #527dcc;
    background-color: #396eff;
    font-size: 14px;
  }
}
