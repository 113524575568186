.countByRating {
  .rating {
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    .star {
      width: 12px;
      height: 12px;
      background: url(../../images/ic_star_full.png) no-repeat center;
      background-size: 12px !important;
      display: flex;
      margin-right: 4px;
    }

    .num {
      width: 16px;
      color: #396eff;
      font-size: 12px;
      margin-right: 4px;
    }

    .progress {
      background: #e9edf4;
      width: 80px;
      height: 4px;
      display: flex;
      margin-right: 8px;

      .inner {
        background: #396eff;
        height: 100%;
      }
    }

    .count {
      font-size: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
