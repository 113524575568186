.satisfactionPage {
  padding: 0 20px;
  padding-bottom: 23px;

  .header {
    margin-bottom: 18px;
    padding-top: 24px;

    .total {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .body {
    position: relative;
    padding-bottom: 23px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .satisfactions {
      display: flex;
      flex-direction: column;

      .satisfaction {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .shape {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }

        .label {
          width: 40px;
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
        }

        .count {
          width: 36px;
          font-size: 14px;
          text-align: right;
          margin-right: 4px;
        }

        .percent {
          font-size: 12px;
        }

        &.good {
          .shape {
            background: #396eff;
          }

          .percent {
            color: #396eff;
          }
        }

        &.fair {
          .shape {
            background: #869ab7;
          }

          .percent {
            color: #869ab7;
          }
        }

        &.poor {
          .shape {
            background: #ed7583;
          }

          .percent {
            color: #ed7583;
          }
        }
      }
    }
  }

  .description {
    p {
      display: flex;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        &:first-child {
          margin-right: 4px;
        }
      }
    }
  }
}
