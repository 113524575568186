.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 56px);
  display: flex;
  .loadingWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-self: center;
    justify-content: center;
  }
  iframe {
    width: 100%;
    height: calc(100vh - 56px);
    -webkit-overflow-scrolling: touch;
  }
}
