.moviePlayer {
  display: flex;
  flex-direction: column;
  transition: width .3s ease-in-out, height .3s ease-in-out;
  position: relative;

  .frame {
    display: flex;
    position: relative;
    background: #000000;
    transition: width .3s ease-in-out, height .3s ease-in-out;

    iframe {
      transition: width .3s ease-in-out, height .3s ease-in-out;
    }
  }

  .overview {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    & > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .interface {
    display: flex;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .3s opacity ease-in-out;

    &.isVisible {
      opacity: 1;
    }

    .centerInterface {
      .loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      .pauseButton {
        width: 128px;
        height: 128px;
        border: none;
        appearance: none;
        position: absolute;
        padding: 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: url(../../images/ic_video_pause.png) no-repeat center;
        background-size: 56px;
      }

      .playButton {
        width: 128px;
        height: 128px;
        border: none;
        appearance: none;
        position: absolute;
        padding: 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: url(../../images/ic_video_play.png) no-repeat center;
        background-size: 56px;
      }
    }


    .bottomInterface {
      display: flex;
      width: 100%;
      position: absolute;
      bottom: 16px;
      padding: 0 16px;
      justify-content: space-between;
      box-sizing: border-box;

      & > div {
        display: flex;
        align-items: center;
      }

      .fullScreen {
        width: 16px;
        height: 16px;
        background: url(../../images/ic_fullscreen.svg) no-repeat center;
        border: none;
        margin-left: 8px;
      }
    }
  }

  .progressive {
    display: flex;
    position: absolute;
    bottom: 0;
    height: 16px;
    width: 100%;
    transform: translateY(8px);

    .progress {
      background: #396eff;
      height: 4px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .cursor {
      user-select: auto;
      width: 16px;
      height: 16px;
      position: absolute;
      background: #396eff;
      box-shadow: 0 2px 4px 0 rgba(#4c414d, .3);
      border-radius: 50%;
      transform: translateX(-8px);
      cursor: pointer;
    }
  }

  &.isEnableFullScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    overflow: hidden;
  }
}

.developmentSkip {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: #396eff;
  color: #fff;
  padding: 5px;
  border: none;
}
