.profileDetail {
  display: flex;
  flex-direction: column;

  .background {
    display: flex;
    width: 100%;
    height: 240px;

    .backgroundImage {
      height: 100%;
      width: 100%;
      background-size: 100% !important;
      background-position: center !important;
    }
  }

  .header {
    position: relative;
    padding: 0 20px;
    padding-bottom: 24px;

    .profile {
      background: #ffffff;
      position: absolute;
      width: 80px;
      height: 80px;
      top: -20px;
      border: solid #fff 4px;
      z-index: 9;

      .profileImage {
        width: 100%;
        height: 100%;
      }
    }

    .information {
      padding-left: 100px;
      padding-top: 16px;

      .fullName {
        font-weight: 600;
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 20px;
      }

      .office {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .description {
    padding: 0 20px;
    padding-bottom: 20px;

    .inner {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 20px;
      border-bottom: solid #e9edf4 1px;
    }
  }

  .actions {
    display: flex;
    padding: 16px 20px 20px 20px;

    a {
      width: 160px;
      appearance: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #396eff;
      border: solid #396eff 1px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      height: 40px;
    }
  }

  .officePhoneNumber {
    padding-left: 26px;
    font-size: 12px;
    line-height: 20px;
    color: #869ab7;
    margin-bottom: 8px;
    background: url(../../images/pic_tel.svg) no-repeat left top 3px;
  }

  .officeAddress {
    padding-left: 26px;
    font-size: 12px;
    line-height: 20px;
    color: #869ab7;
    background: url(../../images/pic_location.svg) no-repeat left top 3px;
  }
}

.field {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 12px;
    line-height: 12px;
    color: #869ab7;
    margin-bottom: 8px;
  }

  .value {
    font-size: 14px;
    line-height: 16px;
  }

  .children {
    padding-top: 16px;
  }
}
