.starRating {
  display: flex;

  .star {
    display: flex;
    width: 13px;
    height: 13px;
    background: #efefef;
    position: relative;
    margin: 1px;

    .background {
      background: #396eff;
    }

    .image {
      background: url(../../images/through-star.png) no-repeat center;
      background-size: 100%;
    }

    .background, .image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
