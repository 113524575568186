.sidePanel {
  border-radius: 8px;
  box-shadow: 0 2px 0 0 #ccd8e8;
  border: solid 1px #e9edf4;
  background-color: #ffffff;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  position: relative;

  .title {
    font-size: 12px;
    line-height: 1;
    font-weight: normal;
    color: #afc2db;
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;

    .subtitle {
      font-size: 12px;
      margin-bottom: 8px;
      color: #414d6b;
    }

    .percent {
      font-size: 18px;
      line-height: 1.11;
      letter-spacing: normal;
      color: #396eff;
    }
  }

  .canvas {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}
