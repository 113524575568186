.studyItem {
  display: flex;
  position: relative;
  border: solid #e9edf4 1px;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 #e9edf4;
  padding: 20px;
  margin-bottom: 16px;
  justify-content: space-between;

  .header {
    display: flex;
    flex-direction: column;

    .no {
      font-size: 12px;
      color: #869ab7;
      line-height: 12px;
      margin-bottom: 8px;
    }

    .title {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 32px;
    }

    .progressWrap {
      display: flex;
      align-items: center;

      .progress {
        width: 100px;
        height: 4px;
        position: relative;
        border-radius: 4px;
        background: #e9edf4;

        .inner {
          height: 100%;
          background: #396eff;
          border-radius: 4px;
        }
      }

      .percentage {
        font-size: 10px;
        color: #396eff;
        margin-left: 8px;
      }
    }

    .blockedReason {
      padding-left: 20px;
      background: url(../../images/ic_lock.svg) no-repeat left center;
      font-size: 12px;
      color: #afc2db;
      line-height: 20px;
    }
  }

  .play {
    width: 80px;
    height: 80px;
    background-color: #e9edf4;
    border-radius: 4px;
    background-image: url(../../images/ic_video_play.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  &.isBlocked {
    background: #f7f8fb;

    .header {
      .no {
        color: #afc2db;
      }

      .title {
        color: #869ab7;
      }
    }
  }
}
