@import '~@PRNDcompany/colors/colors';

.monthlyTrades {
  display: flex;
  flex-direction: column;
  background: $base_gray2;
  min-height: calc(100vh - 313px);
  width: 100%;
  .inner {
    width: 100%;
    height: 100%;
    padding: 32px 24px 32px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      &.isDetail {
        margin-bottom: 0;
      }

      .monthlyTrade {
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .empty {
        font-size: 12px;
        font-weight: normal;
        color: #afc2db;
        text-align: center;
        padding-top: 108px;
        margin-top: 52px;
        background: url(../../images/placeholder_history.svg) no-repeat top center;
      }
    }

    .detail {
      appearance: none;
      border: solid #e9edf4 1px;
      background: none;
      color: #396eff;
      height: 40px;
      font-size: 14px;
      border-radius: 4px;
    }
  }
}
