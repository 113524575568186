.closeButton {
  height: 56px;
  display: flex;
  width: 100%;

  .back {
    width: 56px;
    height: 56px;
    background: url(../../images/btn_close.svg) no-repeat center;
    background-size: 56px !important;
    border: none;
    appearance: none;
  }
}
