.studyVideo {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .player {
    display: flex;
  }

  .additional {
    font-size: 12px;
    line-height: 20px;
    padding: 16px 20px;
    color: #869ab7;
    display: flex;
    flex-direction: column;

    p {
      display: flex;

      span {
        &:first-child {
          margin-right: 4px;
        }
      }
    }
  }
}
