.study {
  .title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 24px;
    line-height: 1;
    height: 80px;
  }

  .studies {
    padding: 0 20px;
  }
}
