.carsItem {
  border-radius: 4px;
  border: solid #e9edf4 1px;
  box-shadow: 0 2px 0 0 #e9edf4;
  box-sizing: border-box;
  position: relative;

  .inner {
    display: flex;
    position: relative;
    height: 100%;

    .thumbnail {
      background-size: 100% !important;
      border-radius: 4px;
      position: absolute;
      right: 0;
    }

    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .carNumber {
        font-weight: normal;
      }

      .star {
        margin-right: 1.3157895%;
      }

      .startContainer {
        display: flex;
      }

      .statusDisplay {
        font-weight: normal;

        &.traded {
          color: #396eff;
        }

        &.failed {
          color: #ed7583;
        }
      }
    }
  }
}
