.successRatePage {
  padding: 0 20px;
  padding-bottom: 23px;
  border-bottom: solid #e9edf4 1px;
  position: relative;

  .header {
    padding-top: 16px;
    padding-bottom: 26px;

    .selectedCarsCount {
      display: flex;
      padding-bottom: 16px;

      .label {
        display: flex;
        width: 60px;
        margin-right: 16px;
        font-size: 14px;
      }

      .value {
        display: flex;
        width: 40px;
        font-size: 14px;
        justify-content: flex-end;
        font-weight: 600;
        position: relative;

        .percent {
          display: flex;
          position: absolute;
          transform: translateX(100%);
        }
      }
    }

    .divider {
      width: 116px;
      height: 1px;
      background: #e9edf4;
      margin-bottom: 15px;
    }
  }

  .columns {
    display: flex;
    flex-direction: column;

    .column {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .shape {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        display: flex;
        width: 60px;
        font-size: 14px;
      }

      .count {
        display: flex;
        width: 40px;
        font-size: 14px;
        justify-content: flex-end;
        font-weight: 600;
        margin-right: 4px;
      }

      .percent {
        font-size: 12px;
      }

      &.traded {
        .shape {
          background: #396eff;
        }

        .percent {
          color: #396eff;
        }
      }

      &.failed {
        .shape {
          background: #e9edf4;
        }

        .percent {
          color: #869ab7;
        }
      }
    }
  }

  .description {
    padding-top: 20px;

    p {
      display: flex;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      & > span {
        &:first-child {
          margin-right: 4px;
        }
      }
    }
  }
}
