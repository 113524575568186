.studyNavigator {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 0 16px 0 rgba(#28414d, .16);
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  box-sizing: border-box;
  background: #ffffff;
  transition: transform .3s ease-in-out;

  .prev, .next {
    border: none;
    position: relative;
    height: 100%;
    font-size: 14px;
    white-space: nowrap;
  }

  .prev {
    background: none;
    color: #396eff;
    width: 68px;
    height: 56px;
  }

  .next {
    width: 120px;
    height: 56px;
    background: #fbfcff;
    border: solid #e9edf4 1px;
    color: #ccd8e8;
    border-radius: 3px;
    transition: color .3s, border .3s, background .3s;

    &.availableNext {
      border-color: #527dcc;
      background: #396eff;
      color: #fff;
    }
  }

  &.isEnableFullScreen {
    transform: translateY(100%);
  }
}
