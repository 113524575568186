.registerSelect {
  height: 40px;
  min-width: 106px;
  border-radius: 2px;
  border: solid 1px #ccd8e8;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 12px 16px;
  cursor: pointer;
  //background-image: url(../../images/register-select-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  position: relative;
  padding-right: 48px;
  white-space: nowrap;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out;

  &:hover {
    border-color: #aebaca;
  }

  &.isVisible {
    border-color: #396eff !important;
  }

  .selectArea {
    width: 100%;
  }

  .placeholder {
    font-size: 14px;
    line-height: 1.14;
    color: #afc2db;
  }

  .value {
    font-size: 14px;
    line-height: 1.14;
    color: #272e40;
  }

  &.b {
    border-radius: 0;
    border: solid #e9edf4 1px;
    height: 48px;
    padding: 14px 20px;
    background-image: url(../../images/btn_dropdown_down.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 48px;
    padding-right: 48px;


    .value, .placeholder {
      line-height: 1.3;
    }
  }
}

.options {
  max-height: 220px;
  position: absolute;
  background: #fff;
  border: solid 1px #ccd8e8;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  top: 42px;
  left: 0;
  transform: translateY(40px);
  border-radius: 2px;
  overflow: auto;
  display: flex;
  z-index: 999;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;

  .option {
    display: flex;
    position: relative;
    appearance: none;
    border: none;
    background: #ffffff;
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    border-bottom: solid rgba(#afc2db, .25) 1px;
    padding: 12px 16px;
    text-align: left;
    font-size: 14px;
    color: #272e40;
  }

  &.b {
    top: 50px;
  }
}
