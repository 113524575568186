.previewCars {
  display: flex;
  flex-direction: column;
  background: #fbfcff;
  min-height: calc(100vh - 392px);

  &.isEmpty {
    justify-content: center;
    align-items: center;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: normal;
      color: #afc2db;
      font-size: 12px;
      padding-top: 108px;
      background: url(../../images/placeholder_trade.svg) no-repeat top center;
    }
  }
}
