.recentReviewsItem {
  display: flex;
  margin-bottom: 20px;

  .left {
    display: flex;
    width: 48px;

    .thumbnail {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #efefef;
      background-image: url(../../images/placeholder_car.svg);
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 32px !important;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);

    .review {
      border: solid #e9edf4 1px;
      padding: 16px;
      background: #ffffff;
      border-radius: 2px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 8px;

      &::after, &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 6px solid #fff;
        border-left: 5px solid transparent;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-11px, 9px);
      }

      &::before {
        border-right: 6px solid #e9edf4;
        transform: translate(-12px, 9px);
      }

      .title {
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      .rating {
        margin-bottom: 16px;

        .star {
          margin-right: 2px;
        }
      }

      .content {
        font-size: 14px;
        line-height: 24px;
        white-space: pre-wrap;
        margin-bottom: 8px;
      }

      .image {
        height: 140px;
        margin-bottom: 16px;
        background-color: #efefef;
        background-size: 100% !important;
        background-position: center !important;
      }

      .inputtedAtDisplay {
        font-size: 12px;
        color: #869ab7;
      }
    }
  }
}
