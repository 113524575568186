.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 20px 20px 20px;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #869ab7;
  }
  .radioGroups {
    margin-top: 28px;
    .radio {
      display: flex;
      cursor: pointer;
      font-size: 12px;
      align-items: center;
      margin-right: 30px;

      .shape {
        min-width: 20px;
        min-height: 20px;
        align-self: flex-start;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
        box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #396eff;
          opacity: 0;
          transform: scale(0);
          transition: opacity 0.15s ease, transform 0.15s ease;
        }
      }
      .labelWrapper {
        flex-basis: 100%;
      }

      .label {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }

      &.isSelected {
        .shape {
          box-shadow: inset 0 0 0 1px #396eff, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

          &::after {
            opacity: 1;
            transform: scale(0.6);
          }
        }
      }
    }
    .secondRadio {
      margin-top: 28px;
    }
  }
  .description {
    margin-top: 16px;
    font-size: 12px;
    color: #869ab7;
    line-height: 20px;
  }
  .textareaWrapper {
    transition: height 0.15s ease-in-out, opacity 0.15s ease-in-out;
    overflow: hidden;
    height: 0;
    margin-top: 16px;
    opacity: 0;
  }
  .display {
    opacity: 1;
    min-height: 82px;
  }
  .submit {
    position: relative;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    height: 56px;
    background-color: #679dff;
    border: 1px solid #527dcc;
    color: white;
    border-radius: 3px;
  }
}
