.recentReviews {
  display: flex;
  flex-direction: column;
  background: #fbfcff;
  min-height: calc(100vh - 402px);

  &.isZeroReviews {
    align-items: center;
    justify-content:center;
  }

  .inner {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;

    .title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .loading {
      display: flex;
      padding: 50px 0;
    }
  }

  .zeroReviews {
    display: flex;
    justify-content: center;

    .title {
      color: #afc2db;
      font-size: 12px;
      line-height: 12px;
      font-weight: normal;
      padding-top: 108px;
      background: url(../../images/placeholder_review.svg) no-repeat top center;
    }
  }
}
