.ratingChart {
  width: 104px;
  height: 104px;
  position: relative;
  margin-right: 4px;
  top: -4px;

  .canvas {
    width: 104px;
    height: 104px;
  }

  .question {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 61px;
    left: 54px;
    border: none;
    padding: 0;
    background: url(../../images/oval-4@2x.png) no-repeat;
    background-size: 100%;
  }
}

.portal {
  padding: 20px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  .additionalModal {
    max-width: 320px;
    width: 100%;
    border-radius: 4px;
    height: 248px;
    background: #ffffff;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, .3);

    .inner {
      padding: 32px;

      .title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }

      .p {
        display: flex;
        font-size: 14px;
        line-height: 24px;

        span {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      padding: 0 8px;

      .ok {
        width: 58px;
        height: 48px;
        position: relative;
        color: #396eff;
        border: none;
        background: none;
        appearance: none;
      }
    }
  }
}
