.tradedCarsPage {
  padding: 20px 20px 32px 20px;

  .header {
    margin: 38px 0;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:first-child) {
        margin-top: 20px;
      }
      .count {
        width: 90px;
        display: flex;

        .title {
          font-size: 24px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }

    .successWrapper {
      display: flex;

      .success {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: url(../../images/checkbox_normal.svg);

        &:last-child {
          margin-right: 0;
        }

        &.hasSuccess {
          background: url(../../images/success_checkbox_check.svg);
        }
      }
    }
  }

  .description {
    margin-bottom: 24px;
    .heavyDealer {
      color: #396eff;
      text-decoration: underline;
    }
  }
}
