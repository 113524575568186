.questionRadio {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .shape {
    width: 24px;
    height: 24px;
    background: url(../../../images/radio_unchecked.svg);
    background-size: 100% !important;
    margin-right: 12px;
  }

  .name {
    font-size: 14px;
    line-height: 16px;
  }

  &.isSelected {
    .shape {
      background: url(../../../images/radio_checked.svg);
    }

    .name {
      font-weight: bold;
    }
  }

}

.questionItem {
  padding: 24px 32px;

  .header {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
    font-weight: bold;

    .index {
      color: #396eff;
      margin-right: 8px;
    }

    .title {
    }
  }

  .currentValue {
    margin-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(#396eff, .15);
    border: solid #396eff 1px;
    display: inline-block;
    height: 32px;
    background: rgba(#679dff, .04);
    padding: 10px 12px;
    box-sizing: border-box;
    color: #396eff;
    line-height: 12px;
    font-size: 12px;

    .text {
      position: relative;
      top: -1px;
    }
  }

  .content {
    display: flex;
    padding-left: 28px;
    flex-direction: column;
  }
}
