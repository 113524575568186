.officeInfoUpdate {
  .header {
    padding: 0 32px;
    padding-bottom: 32px;
    padding-top: 56px;

    .title {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 8px;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 24px;
  }

  .input {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    border: solid #e9edf4 1px;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    transition: border-color 0.3s ease-in-out;

    &:focus {
      border-color: #396eff;
    }

    &::placeholder {
      color: #ccd8e8;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px;

    .updateButton {
      width: 120px;
      height: 56px;
      font-size: 14px;
      background: #efefef;
      color: #fff;
      line-height: 16px;
      appearance: none;
      position: relative;
      border: none;
      border-radius: 3px;
      transition: background 0.3s ease-in-out;

      &.isActivated {
        background: #396eff;
      }
    }
  }

  .closeButton {
    position: absolute;
    z-index: 9999;
  }
}

.blind {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
