.tracking {
  max-width: 640px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .content {
    background-color: #414d6b;
    padding: 48px 20px 20px 28px;
    flex-basis: 28%;
    .title {
      color: white;
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
    }
    .car {
      display: flex;
      margin-top: 24px;
      min-height: 120px;
      background-color: white;
      padding: 20px;
      box-sizing: border-box;
      .thumbnail {
        min-width: 80px;
        width: 80px;
        min-height: 80px;
        height: 80px;
        margin-right: 20px;
        .backgroundImage {
          height: 100%;
          width: 100%;
          background-size: 100% !important;
          background-position: center !important;
        }
        .image {
          border-radius: 4px;
        }
      }
      .carInfo {
        font-size: 14px;
        line-height: 24px;
        .carName {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .carNumber {
            font-weight: bold;
            margin-right: 4px;
          }
        }

        .location {
          margin-top: 16px;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #869ab7;
          line-height: 1;
          .icon {
            margin-right: 4px;
          }
        }
      }
    }
  }

}
