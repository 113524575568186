.toastItem {
  max-width: 90vw;
  color: #fff;
  display: flex;
  white-space: nowrap;
  position: fixed;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  border-radius: 2px;

  .icon {
    display: flex;
    width: 30px;
    height: 30px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .message {
    white-space: pre-line;
    line-height: 1.31;
  }

  &.info {
    background: #414d6b;
  }

  &.success {
    background: #61c851;
  }

  &.error {
    background: #ef7061;
  }
}
