.studyDetail {
  padding-bottom: 80px;
  transition: padding-bottom .3s ease-in-out;

  &.isEnableFullScreen {
    padding-bottom: 0;
  }

  .header {
    padding: 0 20px;
    border-bottom: solid #e9edf4 1px;

    .studyOrders {
      display: flex;
      height: 60px;

      .order {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 16px;

        &.isDisabled {
          color: #afc2db;
          padding-left: 20px;
          background: url(../../images/ic_lock.svg) no-repeat left center;
        }

        &.isCompleted {
          color: #414d6b;
        }

        &.isActivated {
          color: #396eff;
        }
      }

      .direction {
        width: 16px;
        background: url(../../images/ic_next_gray.png) no-repeat center;
        background-size: 16px;
        height: 100%;
      }
    }
  }
}

.blind {
  background: rgba(#33414d, .2);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.popup {
  border-radius: 4px;
  position: fixed;
  height: 320px;
  max-width: 320px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  align-items: center;
  display: flex;
  flex-direction: column;

  .image {
    width: 96px;
    height: 96px;
    background: url(../../images/study-completed.gif) no-repeat center;
    background-size: 100%;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .title {
    font-size: 24px;
    line-height: 36px;
    color: #414d6b;
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .exitButton {
    width: 100%;
    height: 56px;
    font-size: 14px;
    background: #396eff;
    border: solid #527dcc 1px;
    color: #fff;
    border-radius: 3px;
  }
}
