.previewCarsGroup {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .title {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .thumbnails {
    display: flex;
    justify-content: start;
    margin-bottom: 16px;

    .thumbnail {
      margin-right: 8px;
      border-radius: 4px;
      background-size: 100% !important;
      background-position: center !important;
      box-shadow: 0 2px 0 0 #ccd8e8;
    }
  }

  .more {
    appearance: none;
    font-size: 14px;
    border: solid #e9edf4 1px;
    background: none;
    color: #396eff;
    height: 40px;
    border-radius: 4px;
  }
}
