.reviewPage {
  padding: 0 20px;
  border-bottom: solid #e9edf4 1px;
  padding-bottom: 23px;

  .header {
    display: flex;
    margin-bottom: 28px;

    .columns {
      display: flex;

      .column {
        width: 80px;
        display: flex;
        flex-direction: column;

        .value {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 8px;

          .suffix {
            font-size: 12px;
          }
        }
      }

      .label {
        font-size: 12px;
        line-height: 12px;
        color: #869ab7;
      }
    }
  }

  .body {
    margin-bottom: 24px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .divider {
      width: 1px;
      height: 92px;
      background: #e9edf4;
    }
  }

  .description {
    font-size: 12px;
    line-height: 20px;

    p {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
