.cars {
  padding: 0 20px;

  .count {
    margin-left: 5px;
    color: #396eff;
  }

  .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content:center;
    padding: 50px 0;
  }
}
